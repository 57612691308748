const navbarNavigations = [
  {
    title: 'Alle Factchecks',
    url: '/factchecks',
    color: 'primary',
    highlight: false,
  },
  {
    title: 'Over deCheckers',
    url: '/over-ons',
    color: 'primary',
    highlight: false,
  },
  {
    title: 'Blog',
    url: '/blog',
    color: 'primary',
    highlight: false,
  },
  {
    title: 'Contact',
    url: '/contact',
    color: 'primary',
    highlight: false,
  },
  {
    title: 'Suggereer een factcheck',
    url: '/factcheck-suggereren',
    color: 'secondary',
    highlight: false,
  },
  {
    title: 'Nieuwsbrief',
    url: '/nieuwsbrief',
    color: 'secondary',
    highlight: false,
  },
  // todo: change color
  {
    title: 'Ik steun',
    url: '/donatie',
    color: 'success',
    highlight: true,
  }
];

export default navbarNavigations;