import FlexBox from 'components/FlexBox';
import { Badge, Box, Container, Dialog, Drawer, IconButton, Divider, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { layoutConstant } from 'utils/constants';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import SearchBox from '../search-box/SearchBox'; // component props interface
import { Menu, Search, Close, ArrowForward } from '@mui/icons-material';
import NavLink from 'components/nav-link/NavLink';
import theme from 'theme/theme';
import Image from "next/image";

import navbarNavigations from 'data/navbarNavigations';
// styled component
export const HeaderWrapper = styled(Box)(({
	theme
}) => ({
	position: 'relative',
	zIndex: 1,
	height: layoutConstant.headerHeight,
	transition: 'height 250ms ease-in-out',
	boxShadow: theme.shadows[2],
	background: 'white',
	[theme.breakpoints.down('sm')]: {
		height: layoutConstant.mobileHeaderHeight
	}
}));

const StyledNavLink = styled(NavLink)(({ active, color }) => ({
	transition: 'color 150ms ease-in-out',
	cursor: 'pointer',
	marginRight: '2rem',
	userSelect: "none",
	color: theme.palette[color][100],
	fontSize: 30,
	fontWeight: '600',
	marginLeft: 20,
	wordBeak: 'break-word',
	borderBottom: active ? `2px solid ${theme.palette.secondary[100]}` : 'none',
	paddingBottom: 3,
	'&:last-child': {
		marginRight: '0'
	}
}));

const Header = ({ isFixed, className }) => {
	const [navigation, setNavigation] = useState(false);
	const toggleNaviation = () => setNavigation(!navigation);

	const [searchBoxOpen, setSearchBoxOpen] = useState(false);
	const toggleSearchBox = () => setSearchBoxOpen(!searchBoxOpen);

	const renderNavBar = (list) => {
		return list?.map(nav => {
			return (<div>
				<StyledNavLink href={nav.url} key={nav.title} color={nav.color} style={{ display: "flex", alignItems: "center" }} onClick={() => setNavigation(false)}>
					{
						nav.color === 'secondary' && <ArrowForward color={theme.palette.secondary.main} sx={{ marginLeft: 2, marginRight: 2 }} />
					}
					{nav.title}
				</StyledNavLink>
				<hr style={{ marginLeft: 20, marginRight: 20, borderTop: '1px solid #d2d2ea' }} />
				<br />
				<Divider />
			</div>)
		});
	};

	const closeAll = () => {
		setSearchBoxOpen(false);
		setNavigation(false)
	}


	let screenHeight = 0;

	if (typeof window !== 'undefined') {
		screenHeight = screen.height
	}

	const theme = useTheme();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
	return (
        <HeaderWrapper className={clsx(className)}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
					responsive: {
						md: {
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						},
					},
				}}
			>
				{/* Logo */}
				<FlexBox
					alignItems="center"
					mr={2}
					minWidth="170px"
					sx={{
						display: {
							xs: 'flex',
							md: 'flex',
						},
					}}
				>
					<Link href="/">
						<div>
							{<Image
                                height={isDesktop ? 48 : 36}
                                width={isDesktop ? 300 : 225}
                                mb={0.5}
                                src="/assets/images/branding/logo/logo-header.png"
                                alt="logo"
                                style={{
                                    maxWidth: "100%",
                                    height: "auto"
                                }} />}
						</div>
					</Link>
				</FlexBox>

				{/* Hamburger menu and search icon */}
				<FlexBox sx={{
					display: {
						xs: 'flex',
						md: 'none',
					},
				}}>
					{
						!searchBoxOpen && !navigation ? <IconButton onClick={toggleSearchBox}>
							<Search color='secondary' sx={{ fontSize: 30 }} />
						</IconButton> : null
					}

					{
						searchBoxOpen || navigation ?
							(
								<IconButton sx={{ mr: 1 }} onClick={closeAll} aria-label="close">
									<Close color='primary' sx={{ fontSize: 30 }} />
								</IconButton>
							)
							:
							(
								<IconButton sx={{ mr: 1 }} onClick={toggleNaviation} aria-label="navigation">
									<Menu color='primary' sx={{ fontSize: 30 }} />
								</IconButton>
							)
					}

				</FlexBox>

				{/* Search box */}
				<FlexBox
					flex="0.5 0 0"
					sx={{
						display: {
							xs: 'none',
							md: 'flex',
						},
					}}
				>
					<SearchBox />
				</FlexBox>
			</Container>
			{
				searchBoxOpen &&
				<>
					<div style={{ width: '100vw', position: 'absolute', height: 50, background: 'white' }}></div>
					<div style={{
						background: theme.palette.primary.main, clipPath: 'polygon(0% 0%, 80% 0px, 85% 21%, 100% -42%, 100% 100%, 0px 100%)', paddingTop: 30, paddingLeft: 40, paddingRight: 40, height: searchBoxOpen ? '100px' : '0',
						overflow: 'hidden',
						transition: 'height 0.7s ease-in-out',
					}}>
						<SearchBox setSearchBoxOpen />
					</div>
				</>

			}

			{
				navigation && <div style={{
					background: '#f0f2ff',
					height: screenHeight,
					overflow: 'hidden',
					paddingTop: 20
				}}>
					{renderNavBar(navbarNavigations)}
				</div>
			}

		</HeaderWrapper>
    );
};

export default Header;