import BazarCard from 'components/BazarCard';
import FlexBox from 'components/FlexBox';
import NavLink from 'components/nav-link/NavLink';
import navbarNavigations from 'data/navbarNavigations';
import { Box, Container, MenuItem, styled } from '@mui/material';
import React from 'react'; // component props interface
import { useRouter } from 'next/router';
import theme from 'theme/theme';
import { ArrowBack } from '@mui/icons-material';

const StyledNavLink = styled(NavLink)(({ active, color, highlight }) => ({
	transition: 'color 150ms ease-in-out',
	cursor: 'pointer',
	marginRight: '2rem',
	userSelect: "none",
	color: highlight ? 'white' : theme.palette[color][100],
	paddingLeft: highlight ? 10 : 0,
	paddingRight: highlight ? 10 : 0,
	paddingTop: highlight ? 4 : 4,
	borderBottom: active ? `2px solid ${theme.palette[color][100]}` : 'none',
	paddingBottom: 3,
	fontSize: 17,
	background: highlight ? theme.palette[color][100] : 'transparent',

	borderBottom: `2px solid transparent`,
	'&:hover': {
		color: highlight ? 'white' : theme.palette[color][100],
		borderBottom: `2px solid ${theme.palette[color][100]}`,
	},
	'&:last-child': {
		marginRight: '0'
	}
}));

const NavBarWrapper = styled(BazarCard)(({
	theme
}) => ({
	display: 'block',
	position: 'relative',
	background: 'transparent',
	boxShadow: 'none',
	height: '60px',
	borderRadius: '0px',
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}));

const InnerContainer = styled(Container)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100%',
}));

const Navbar = (props) => {

	const router = useRouter();

	const renderNavBar = (list, isRoot = false) => {
		return list?.map(nav => {
			return <StyledNavLink href={nav.url} key={nav.title} color={nav.color} highlight={nav.highlight} active={nav.url == router.asPath}>
				{nav.title}
			</StyledNavLink>
		});
	};

	return (

		props.showReturn ?
			(
				<>
					<NavBarWrapper elevation={2} hoverEffect={false}>
						<InnerContainer sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<a onClick={function () { router.back() }} style={{ color: '#8e8e8e', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
								<ArrowBack style={{ marginRight: '0.1rem' }} />
								<span style={{ textDecoration: 'underline' }}>Terug</span>
							</a>
							<FlexBox>{renderNavBar(navbarNavigations, true)}</FlexBox>
						</InnerContainer>
					</NavBarWrapper>
				</>
			)
			:
			(
				<>
					<NavBarWrapper elevation={2} hoverEffect={false}>
						{<InnerContainer sx={{
							justifyContent: 'flex-end'
						}}>
							<FlexBox>{renderNavBar(navbarNavigations, true)}</FlexBox>
						</InnerContainer>}
					</NavBarWrapper>
				</>
			)
	);
};

export default Navbar;